<template>
  <div class="overlay-ads-outer">
    <div class="overlay-ads"></div>
    <div id="divadsensedisplaynone">
      <Adsense
          ins-class="adsbygoogle"
          ins-style="display:block;"
          data-ad-client="ca-pub-4903531884034952"
          data-ad-slot="8701135410"
          data-full-width-responsive="true"
          data-ad-format="auto"
          >
      </Adsense>
    </div>
  </div>
</template>

<script>
export default {
    name: 'AdsModal',
}
</script>
<style scoped lang="scss">
.overlay-ads-outer {
  margin-top: -19rem;
}
.overlay-ads {
    width: 100%;
    position: absolute;
    height: 315px;
    background: #fff;
}
</style>