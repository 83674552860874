<template>
  <div class="event-detail-page w-100 mt-0 mt-md-3">
    <b-container class="container-xlg px-0">
      <esports-event-detail-loading v-if="isLoading && !event" />
      <b-card
        v-if="event"
        class="mb-2 rounded-0 border-0 p-0"
        body-class="px-0 pt-0 pt-md-3"
      >
        <b-card-img-lazy
          v-bind="mainProps()"
          :src="imageEvent"
          class="rounded-0 img-banner"
          top
        ></b-card-img-lazy>
        <div class="body-event pt-4 px-3 pb-3 px-xxl-0">
          <b-card-title class="mb-4 py-md-4 mb-md-5">{{
            event.title
          }}</b-card-title>

          <SectionRanking class="mb-3 mb-md-5" v-if="event.winner || event.winnerOther" :gamers="gamer_ranking" />

          <!-- <div class="line-title mb-3 pb-md-3" v-if="event.winner">
            <h5>優勝者</h5>
            <p class="pt-2 pt-md-4">
              <b-avatar
                size="lg"
                :src="imageUserUrl(event.winnerInfo)"
                class="mr-3"
              >
                <template #badge
                  ><b-icon class="text-warning" icon="star-fill"></b-icon
                ></template> </b-avatar
              >{{ event.winnerInfo.name }}
            </p>
          </div> -->
          <div class="line-title mb-3 pb-md-3">
            <h5>ゲームタイトル</h5>
            <p class="pt-2 pt-md-4">{{ event.gameTitle }}</p>
          </div>

          <div class="line-title mb-5 pb-md-4">
            <h5>概要</h5>
            <p class="pt-2 pt-md-4">
              {{ event.description }}
            </p>
          </div>
          <div class="line-title mb-3 pb-md-3" v-if="event.prize">
            <h5>賞金</h5>
            <p class="pt-2 pt-md-4">{{ formatPoint(event.prize) }}円</p>
          </div>
          <div class="line-title mb-3 pb-md-3">
            <h5>日程</h5>
            <p class="pt-2 pt-md-4">
              {{ event.startDateText }}&nbsp;&nbsp;〜&nbsp;&nbsp;{{
                event.endDateText
              }}
            </p>
          </div>
          <div class="line-title mb-3 pb-md-3">
            <h5>開催地</h5>
            <p class="pt-2 pt-md-4">{{ event.venue }}</p>
          </div>

    
          <div class="line-title mb-3 pb-md-3">
            <h5>
              {{
                event && event.gamerType === 0
                  ? "出場予定選手、実績"
                  : "出場チーム"
              }}
            </h5>
          </div>
          <div class="btn-actions my-4 py-md-3">
            <b-button @click="showModal('vote_modal')" class="btn-slash mr-md-3"
              ><esports-icon-crown /><span class="position-relative"
                >投票数ランキング</span
              ></b-button
            >
            <template v-if="authenticated">
              <!-- <ShareNetwork
                network="twitter"
                :url="urlDetail"
                :title="event.title"
                :description="event.description"
                @open="showTwitter"
                hashtags="Donate_eSports"
              ></ShareNetwork> -->
              <b-button
                class="btn-slash btn-twitter"
                @click="
                  user.loginMethod !== 3 && !twitter
                    ? onLoginTwitter()
                    : showModalTweet()
                "
                ><esports-twitter-button />
                <span class="position-relative">ツイートで一票 GET</span>
              </b-button>
            </template>
            <template v-else>
              <b-button @click="showLoginModal()" class="btn-slash btn-twitter"
                ><esports-twitter-button /><span class="position-relative"
                  >ツイートで一票 GET</span
                ></b-button
              >
            </template>
            <p class="note-twitter">
              ※URLとハッシュタグをつけてツイートしてください<br />
              ※この大会のツイートでもらえる票は1日3回までです。<br />
              ※ドネスポの<b-link href="https://twitter.com/Donate_eSports" target="_blank">Twitterアカウント</b-link>をフォローしてしてください。
            </p>

            <!-- <template v-if="isSharedCurrentUser">
              <b-button
                v-if="user && user.roleId !== 1"
                @click="
                  authenticated
                    ? showModal('vote_twitter_modal')
                    : showLoginModal()
                "
                class="btn-slash btn-twitter"
                ><esports-twitter-button /><span class="position-relative"
                  >ツイートで一票 GET</span
                ></b-button
              >
              <ShareNetwork
                network="twitter"
                v-else-if="authenticated"
                :url="urlDetail"
                :title="event.title"
                :description="event.description"
                hashtags="Donate_eSports"
              >
                <b-button class="btn-slash btn-twitter"
                  ><esports-twitter-button />
                  <span class="position-relative">ツイートで一票 GET</span>
                </b-button>
              </ShareNetwork>
              <b-button
                v-else
                @click="showLoginModal()"
                class="btn-slash btn-twitter"
                ><esports-twitter-button /><span class="position-relative"
                  >ツイートで一票 GET</span
                ></b-button>
            </template>
            <template v-else>
              <ShareNetwork
                network="twitter"
                :url="urlDetail"
                :title="event.title"
                :description="event.description"
                hashtags="Donate_eSports"
              >
                <b-button class="btn-slash btn-twitter"
                  ><esports-twitter-button />
                  <span class="position-relative">ツイートで一票 GET</span>
                </b-button>
              </ShareNetwork>
            </template> -->
          </div>

          <div class="content-player px-3 px-md-0 mb-3 pb-md-3">
            <b-row
              align-v="stretch"
              v-for="gamer in event.gamers"
              :key="gamer.gamerId"
              class="mx-md-0 flex-column flex-lg-row pb-3 pb-lg-0"
            >
              <b-col class="player-name py-2 col-cus"
                ><h4 class="mb-0">{{ gamer.name }}</h4></b-col
              >
              <b-col md="6" lg="7" class="py-2 col-cus">
                <h4 class="game mb-0" v-if="event && event.gamerType === 0">
                  {{ getArchivement(gamer.achievement) }}
                </h4>
                <h4 class="game mb-0" v-else>
                  <template v-if="gamer.players">
                    <span
                      class="d-block"
                      v-for="(player, index) in gamer.players"
                      :key="index"
                      >{{ player }}</span
                    >
                  </template>
                </h4>
              </b-col>
              <b-col class="py-2 col-cus text-center text-lg-left">
                <b-button
                  v-if="isVote"
                  @click="getGamerDetail(gamer.gamerId, true, gamer)"
                  class="btn-slash btn-player ml-md-2 position-relative"
                >
                  <span class="position-relative">応援する</span>
                  <esports-loading-button
                    v-if="gamer.isLoading"
                    width="40px"
                    outerBorder="#333"
                    innerBorder="#eee"
                    height="40px"
                    class="btn-loading"
                  />
                </b-button>
              </b-col>
            </b-row>
            <p class="start-game-date" v-if="isVote">
              {{ event.startDateGame }}
            </p>
          </div>

          <div class="line-title mb-3 pb-md-3">
            <h5 class="mb-2 mb-md-4">配信</h5>
            <template v-if="event.linkInfos">
              <h6
                class="mb-1"
                :key="link[0]"
                v-for="link in Object.entries(event.linkInfos)"
              >
                {{ link[0] }}：
                <b-link
                  class="text-decoration-none"
                  :href="link[1]"
                  target="_blank"
                  >{{ link[1] }}</b-link
                >
                <!-- <b-button
                  class="p-0 border-0 rounded-0"
                  v-b-tooltip="copied ? 'Copied' : 'Copy'"
                  role="button"
                  @click="doCopy(link[1])"
                  >{{ link[1] }}</b-button
                > -->
              </h6>
            </template>
          </div>

          <!-- <div class="btn-actions mb-3 pb-md-3" v-if="event.videoLink">
            <b-button
              @click="showModal('vote_video_modal')"
              title="動画を観る"
              class="btn-slash btn-camera mr-md-3"
              ><esports-icon-camera /><span class="position-relative"
                >動画を観る</span
              ></b-button
            >
          </div> -->

          <div class="line-title mb-3 pb-md-3">
            <h5 class="mb-2 mb-md-4">URL</h5>
            <template v-if="event.urlInfos">
              <h6
                class="mb-1"
                :key="link[0]"
                v-for="link in Object.entries(event.urlInfos)"
              >
                {{ link[0] }}:
                <b-link
                  class="text-decoration-none"
                  :href="link[1]"
                  target="_blank"
                  >{{ link[1] }}</b-link
                >
              </h6>
            </template>
          </div>
          <template v-if="event.extensionInfos">
            <div
              class="line-title mb-3 pb-md-3"
              :key="link[0]"
              v-for="link in Object.entries(event.extensionInfos)"
            >
              <h5 class="mb-2 mb-md-4">{{ link[0] }}</h5>
              <h6 class="mb-1">
                {{ link[1] }}
              </h6>
            </div>
          </template>
        </div>

        <AdsModal />
      </b-card>
    </b-container>
    <!-- modal Vote -->
    <b-modal
      ref="vote_modal"
      content-class="content-vote pb-4 pb-xxl-5"
      body-class="position-relative p-0"
      hide-footer
      hide-header
      size="lg"
      centered
    >
      <div class="header-modal position-relative my-4 py-md-2">
        <h4 class="title">
          <esports-icon-crown width="27" class="mr-2" />
          <span>投票数ランキング</span>
        </h4>
        <span class="close-modal" @click="hideModal('vote_modal')" role="button"
          ><esports-icon-close
        /></span>
      </div>
      <div class="vote-info">
        <b-list-group class="mx-3 mx-xxl-5 px-xxl-5">
          <b-list-group-item
            v-for="(vote, index) in rankVotes"
            :key="index"
            class="d-flex align-items-center rounded-0 border-0 mx-1 mx-xxl-3"
          >
            <b-avatar class="mr-3">{{ index + 1 }}</b-avatar>
            <span class="mr-auto name">{{ vote.name }}</span>
            <!-- <span class="vote pr-0 pr-xxl-4"
              >{{ vote.numberVote }}<sub>票</sub></span
            > -->
            <span class="vote pr-0 pr-xxl-4">&nbsp;</span>
          </b-list-group-item>
        </b-list-group>
      </div>
    </b-modal>
    <!-- modal Vote Twitter -->
    <b-modal
      ref="vote_twitter_modal"
      content-class="content-vote-twitter"
      body-class="position-relative p-0 d-flex align-items-center justify-content-center"
      hide-footer
      hide-header
      size="sm"
      centered
    >
      <div class="vote-twitter text-center">
        <h5 class="mb-4 text-center">
          Twitter投稿で投票チケット<br /><span class="font-weight-bold"
            >{{ numberAddTicket }}票</span
          >獲得しました。
        </h5>
        <b-button
          class="font-weight-bold"
          @click="hideModal('vote_twitter_modal')"
          >閉じる</b-button
        >
      </div>
    </b-modal>

    <!-- modal Ads Google -->
    <b-modal
      ref="ads_google_modal"
      content-class="content-vote-twitter"
      body-class="position-relative p-0 d-flex align-items-center justify-content-center"
      hide-footer
      hide-header
      size="sm"
      centered
    >
      <div class="vote-twitter text-center">
        <h5 class="mb-4 text-center">
          広告で投票チケット<br /><span class="font-weight-bold">{{ numberTickets }}票</span
          >獲得しました。
        </h5>
        <b-button
          class="font-weight-bold"
          @click="hideModal('ads_google_modal')"
          >閉じる</b-button
        >
      </div>
    </b-modal>
    <!-- modal Video -->

    <b-modal
      ref="vote_video_modal"
      content-class="content-video shadow"
      body-class="position-relative p-0"
      hide-footer
      hide-header
      size="lg"
      dialog-class="video-dialog"
      no-close-on-backdrop
      centered
    >
      <span
        class="close-modal"
        @click="hideModal('vote_video_modal')"
        role="button"
        v-if="isShowCloseModal"
        ><esports-icon-close
      /></span>
      <div class="video">
        <!-- <Adsense
          ins-class="adsbygoogle"
          ins-style="display:block;width:100%;height:400px"
          data-ad-client="ca-pub-4903531884034952"
          data-ad-slot="9591714757"
          data-full-width-responsive="true"
          data-ad-format="auto"
        >
        </Adsense> -->
        <div id="adsgoeshere" v-html="adsenseContent"></div>
      </div>
    </b-modal>

    <LoginModal :isShowLoginModal="isShowLoginModal" @resetModal="resetModal" />

    <PlayerDetailModal
      :currentPlayer="currentPlayer"
      :isShowPlayerModal="isShowPlayerModal"
      :isDeadlineVote="isVote"
      :eventId="eventId"
      :currentTickets="currentTickets"
      :isVoteOther="isVoteOther"
      @hideVoteOther="hideVoteOther"
      @showModalAds="showModalAds"
      @resetModal="resetGamerModal"
      @showLoginModal="showLoginModal"
    />
    <b-modal
      content-class="content-vote-twitter"
      body-class="position-relative p-0 d-flex align-items-center justify-content-center"
      hide-footer
      hide-header
      size="sm"
      centered
      @hide="hideModalConfirm"
      v-model="showModalConfirm"
    >
      <div class="vote-twitter text-center">
        <h3 class="mb-4 pb-2 font-weight-bold">動画を見て投票券GET！</h3>
        <h6 class="mb-4 pb-2">動画を視聴しますか？</h6>
        <b-button
          class="font-weight-bold btn-cancel mx-2"
          @click="hideModalConfirm"
          >閉じる</b-button
        >
        <b-button class="mx-2" @click="showModalAds">OK</b-button>
      </div>
    </b-modal>

    <b-modal
      content-class="content-vote-twitter"
      body-class="position-relative p-0 d-flex align-items-center justify-content-center"
      hide-footer
      hide-header
      size="sm"
      centered
      @hide="hideModalConfirmTweet"
      @show="focusTextarea"
      v-model="showModalConfirmTweet"
    >
      <div class="vote-twitter text-right px-4 w-100 py-4">
        <!-- <h6 class="pb-2 text-left" v-if="event">{{ event.title }}</h6> -->
        <b-form-textarea
          v-model="description"
          debounce="500"
          rows="5"
          no-resize
          class="mb-2"
          ref="description"
        ></b-form-textarea>
        <h6 class="text-primary text-left mb-3">{{ urlDetail }}</h6>
        <b-button
          :style="{ width: '90px' }"
          @click="postTweet"
          :disabled="isSubmitted"
        >
          <span class="position-relative">Tweet</span>
          <esports-loading-button
            v-if="isSubmitted"
            width="30px"
            height="30px"
            outerBorder="#333"
            innerBorder="#eee"
        /></b-button>
      </div>
    </b-modal>

    <esports-loading-button
      class="overlay-content-page"
      width="100"
      height="100"
      v-if="isLoading"
    />
  </div>
</template>

<script>
import moment from "moment";
import "moment/locale/ja";
import { mapGetters, mapState } from "vuex";
import LoginModal from "@/components/Modals/LoginModal";
import AdsModal from "@/components/Shared/AdsModal";
import PlayerDetailModal from "@/components/Modals/PlayerDetailModal";
import SectionRanking from "./SectionRanking";
import hello from "hellojs/dist/hello.all.js";
import { OAUTH_PROXY } from "@/utils/constants";
import storage from "@/utils/storage";

moment.locale("ja");

export default {
  name: "eventDetail",
  title: "event_detail",
  components: { LoginModal, PlayerDetailModal, SectionRanking, AdsModal },
  data() {
    return {
      event: null,
      isLoading: false,
      isShowLoginModal: false,
      rankVotes: [],
      currentPlayer: null,
      isShowPlayerModal: false,
      resetLoading: false,
      copied: false,
      currentTickets: 0,
      gamerId: null,
      isShowCloseModal: false,
      DURATION: 5000,
      key: "6JoILXQ4WKd4tMbZMsYoZCaDoWxKOPWL",
      gamer_ranking: [],
      showModalConfirm: false,
      showModalConfirmTweet: false,
      helloSocial: null,
      twitter: null,
      description: "",
      isSubmitted: false,
      isLoginTwitter: false,
      eventTitle: "",
      eventHashtag: "#ドネスポ #Donate_eSports",
      TWITTER_BYTE_INPUT: 280,
      isDisabledTwitter: false,
      rankVote: {
        playerId: "",
        name: ""
      },
      numberAddTicket: 0,
      numberTickets: 0,
      isVoteOther: false,
      otherArchive: {
        gamerId: null,
        name: "応援したい選手",
        achievement: ["TwitterのIDを@含めて入力して下さい"],
        players: ["TwitterのIDを@含めて入力して下さい"]
      },
      adsenseContent: ''
    };
  },
  metaInfo() {
    return {
      title: this.event
        ? "Donate eSports - " + this.event.title
        : "Donate eSports",
      meta: [
        {
          property: "og:title",
          content: this.event ? "Donate eSports - " + this.event.title : "",
          itemprop: "headline",
        },
        {
          property: "og:description",
          content: this.event ? this.event.description : "",
        },
        { property: "og:url", content: this.event ? this.urlDetail : "" },
        {
          property: "og:image",
          content: this.event
            ? process.env.VUE_APP_BACKEND + this.event.picture
            : "",
        },
        { property: "og:image:width", content: "800" },
        { property: "og:image:height", content: "354" },

        { nam: "twitter:card", value: "summary" },
        {
          name: "twitter:title",
          content: this.event ? "Donate eSports - " + this.event.title : "",
          itemprop: "headline",
        },
        { name: "twitter:url", content: this.event ? this.urlDetail : "" },
        {
          name: "twitter:description",
          content: this.event ? this.event.description : "",
        },
        {
          name: "twitter:image",
          content: this.event
            ? process.env.VUE_APP_BACKEND + this.event.picture
            : "",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("auth", ["authenticated", "user"]),
    ...mapState("auth", ["sdkTwitter"]),
    eventId() {
      return this.$route.params.id || "";
    },
    imageEvent() {
      return (
        this.event && `${process.env.VUE_APP_BACKEND + this.event.picture}`
      );
    },
    urlDetail() {
      return window.location.href;
    },
    isDeadlineVote() {
      return this.event && moment().isSameOrAfter(this.event.startDate, "day");
    },
    isVote() {
      return (
        this.event &&
        moment().isSameOrAfter(this.event.startVote, "day") &&
        moment().isSameOrBefore(this.event.endVote, "day")
      );
    },
    isSharedCurrentUser() {
      return this.user && this.event && this.event.shared;
    },
    isAdminUser() {
      return this.authenticated && this.user && this.user.roleId === 1;
    },
    noAddTicketVote() {
      return this.event && moment().isSameOrAfter(this.event.endDate, "day");
    },
  },
  // watch: {
  //   description(nVal) {
  //     let sizeByte = this.renderStringtoByte(nVal);

  //     if(sizeByte > this.TWITTER_BYTE_INPUT) {
  //       console.log('bytes: ', sizeByte);
  //       this.isDisabledTwitter = true;
  //       return;
  //     }

  //     this.isDisabledTwitter = false;
  //   }
  // },
  async mounted() {

    this.helloSocial = hello.init(
      {
        twitter: process.env.VUE_APP_TWITTER_CLIENT_ID,
      },
      {
        oauth_proxy: OAUTH_PROXY,
        redirect_uri: `${process.env.VUE_APP_FRONT_END}/callback`,
      }
    );

    this.helloSocial.on("auth.login", async (response) => {
      if (response) {
        this.twitter = { ...response };

        if (this.isLoginTwitter) {
          this.showModalTweet();
          this.isLoginTwitter = false;
        }
      }
    });

    if (this.eventId) {
      await this.getEventDetail(this.eventId);
      await this.getRanksEvent({
        eventId: this.eventId,
        gamerType: this.event.gamerType,
      });
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== "events") {
      storage.remove("search");
    }
    next();
  },
  methods: {
    async postTweet() {
      let newTwitter = this.user.twitter || this.twitter;
      this.isSubmitted = true;

      await this.openTwitter(newTwitter);
      this.hideModalConfirmTweet();
    },
    async showModalTweet() {
      this.showModalConfirmTweet = true;
      this.eventTitle = this.event.title;
      this.eventTitle = this.eventTitle && this.eventTitle.replaceAll(" ", "_");
      this.eventTitle = `#${this.eventTitle}`;
      this.description = `${this.eventTitle} ${this.eventHashtag} `;
    },
    focusTextarea() {
      setTimeout(() => {
        this.$nextTick(() => {
          this.$refs.description.$el.focus();
        });
      }, 500);
    },
    async getEventDetail(id) {
      this.isLoading = true;
      const result = await this.$store
        .dispatch("event/getEventDetail", id)
        .catch((err) => {
          this.$router.push({ path: "/404" });
        });

      if (result) {
        this.event = result;

        if (this.event.startDate) {
          this.event.startDateText = this.formatDateEvent(
            this.event.startDate,
            true
          );
          this.event.startDateGame = this.formatDateEvent(
            this.event.endVote,
            false
          );
        }

        if (this.event.endDate) {
          this.event.endDateText = this.formatDateEvent(
            this.event.endDate,
            true
          );
        }

        if (this.event.onlineFlag) {
          this.event.venue = "オンライン";
        } else if (!this.event.onlineFlag && !this.event.venue) {
          this.event.venue = "オフライン";
        }

        this.event.extensionInfos = this.event.extensionInfos
          ? JSON.parse(this.event.extensionInfos)
          : null;
        this.event.linkInfos = this.event.linkInfos
          ? JSON.parse(this.event.linkInfos)
          : null;
        this.event.urlInfos = this.event.urlInfos
          ? JSON.parse(this.event.urlInfos)
          : null;

        this.event.gamers = this.event.gamers.map((gamer) => {
          gamer.isLoading = false;
          gamer.picture = `${process.env.VUE_APP_BACKEND + gamer.picture}`;
          return gamer;
        });

        this.event.isVoteOther && this.event.gamers.push(this.otherArchive);

        // if (this.event.gamers.length > 5) {
        //   this.gamer_ranking = this.event.gamers.slice(0, 5);
        // } else {
        //   this.gamer_ranking = this.event.gamers;
        // }

        if(this.event.winner) {
          this.event.winnerInfo.picture = this.event.winnerInfo.picture ? `${process.env.VUE_APP_BACKEND + this.event.winnerInfo.picture}` : null; 
          this.gamer_ranking = [this.event.winnerInfo];
        }

        if(this.event.winnerOther) {
          this.gamer_ranking = [{name: this.event.winnerOther, picture: null}];
        }

      }
      this.isLoading = false;
    },
    async getRanksEvent(params) {
      const result = await this.$store
        .dispatch("vote/getRanksEvent", params)
        .catch((err) => {
          this.catchErrorNetwork(err);
        });

      if (result) {
        this.rankVotes = result;
        // const maxLenVotes = 5;
        
        // if(!this.rankVotes.length) {
        //   for (let i = 0; i < maxLenVotes; i++) {
        //     this.rankVote.playerId = i;
        //     this.rankVotes.push(this.rankVote);
        //   }
        //   return;
        // }

        // if(this.rankVotes.length === maxLenVotes) return;

        // let lenVotes = maxLenVotes - this.rankVotes.length;
        // for (let i = 0; i < lenVotes; i++) {
        //   this.rankVote.playerId = i;
        //   this.rankVotes.push(this.rankVote);
        // }
      }
    },
    async getGamerDetail(id, isConfirmModal, gamer = null) {
      this.gamerId = id;

      if (this.user && !this.isAdminUser) {
        this.setLoadingPlayer(id, true);
        const dataTicket = await this.getCurrentTicketUser();

        if (dataTicket?.currentTickets < 1) {
          if (isConfirmModal) this.showModalConfirm = true;
          this.setLoadingPlayer(id, false);
          return;
        }
      }

      if(!this.user && gamer && !gamer.gamerId) { //User login
        this.isShowLoginModal = true;
        return;
      }

      if(!id) { // no implement
        this.setLoadingPlayer(id, false);
        return;
      }

      this.setLoadingPlayer(id, true);
      let result;

      if (this.event.gamerType === 0) {
        result = await this.$store
          .dispatch("player/getPlayerDetail", id)
          .catch((err) => {
            console.log(err);
            this.setLoadingPlayer(id, false);
          });
      } else {
        result = await this.$store
          .dispatch("team/getTeamDetail", id)
          .catch((err) => {
            console.log(err);
            this.setLoadingPlayer(id, false);
          });
      }

      if (result) {
        this.currentPlayer = result;
        this.currentPlayer.picture =
          process.env.VUE_APP_BACKEND + this.currentPlayer.picture;
        this.currentPlayer.labelGamerName = "チーム名";
        this.currentPlayer.gamerType = this.event.gamerType;

        if (this.event.gamerType === 1) {
          this.currentPlayer.teams = this.currentPlayer.players;
          this.currentPlayer.name = this.currentPlayer.title;
          this.currentPlayer.labelGamerName = "選手名";
        }

        this.currentPlayer.extensionInfos = this.currentPlayer.extensionInfos
          ? JSON.parse(this.currentPlayer.extensionInfos)
          : null;

        this.currentPlayer.deliverySite = this.currentPlayer.deliverySite
          ? JSON.parse(this.currentPlayer.deliverySite)
          : null;

        this.currentPlayer.achievement =
          this.currentPlayer.achievement.filter(Boolean);

        setTimeout(() => {
          this.isShowPlayerModal = true;
          this.setLoadingPlayer(id, false);
        }, 500);
      }
    },
    showModal(name) {
      this.$refs[name].show();
    },
    async showModalAds() {
      this.hideModalConfirm();
      this.showModal("vote_video_modal");
      this.adsenseContent = document.getElementById('divadsensedisplaynone').innerHTML;

      let divAds =  document.getElementById('divadsensedisplaynone');
      let iframeAds = divAds.getElementsByTagName('iframe');

      if(iframeAds[0].getAttribute('allowtransparency') !== undefined && iframeAds[0].getAttribute('allowtransparency')) {
        this.isShowCloseModal = true;
        return;
      }

      const result = await this.$store.dispatch("ads_info/postAd", {
        key: this.key,
      });

      setTimeout(() => {
        this.isShowCloseModal = true;
        if (result) {
          this.numberTickets = result.numberTickets;
          this.showModal("ads_google_modal");
        }
      }, this.DURATION);
    },
    async getCurrentTicketUser() {
      const result = await this.$store.dispatch("user/getCurrentTicketUser");

      if (result) {
        this.currentTickets = result.currentTickets;
        return result;
      }

      return null;
    },
    async hideModal(name) {
      this.$refs[name].hide();

      if (this.gamerId) {
        await this.getGamerDetail(this.gamerId, false);
        this.gamerId = null;
      }

      if(name === 'vote_video_modal') location.reload();
    },
    async openTwitter(params) {
      const result = await this.$store
        .dispatch("ads_info/shareEvent", {
          eventId: this.eventId,
          text: this.description + " " + this.urlDetail,
          accessToken: params.authResponse.oauth_token,
          tokenSecret: params.authResponse.oauth_token_secret,
          currentDate: moment().format("YYYY/MM/DD"),
        })
        .catch((err) => {
          if (err?.errors) {
            this.msgErrors = Object.values(err.errors);
          } else {
            this.msgErrors = [err];
          }
          this.isSubmitted = false;
          this.makeToast("danger", this.msgErrors[0]);
        });

      if (result) {
        this.isShowLoginModal = false;
        this.description = "";

        if (parseInt(result.numberAddTicket, 10) >  0) {
          // await this.getEventDetail(this.eventId);
          this.numberAddTicket = result.numberAddTicket;
          this.showModal("vote_twitter_modal");
        }

        this.isSubmitted = false;
      }
    },
    onLoginTwitter() {
      this.isLoginTwitter = true;
      this.helloSocial("twitter").login();
    },
    showLoginModal() {
      this.isShowLoginModal = true;
      this.resetLoading = false;
    },
    hideVoteOther(payload) {
      this.isVoteOther = payload;
    },
    async resetModal(isHideModal) {
      this.isShowLoginModal = isHideModal;

      if (!this.resetLoading && this.user) {
        await this.getEventDetail(this.eventId);
        this.resetLoading = true;
      }
    },
    async resetGamerModal(isPlayer) {
      this.isShowPlayerModal = isPlayer.isHide;

      if (isPlayer.isSaved) {
        await this.getRanksEvent({
          eventId: this.eventId,
          gamerType: this.event.gamerType,
        });
      }
    },
    formatDateEvent(date, isDay) {
      let year = moment(date).format("YYYY");
      let month = moment(date).format("MM");
      let day = moment(date).format("DD");
      let dayOfWeek = moment(date).format("dd");

      let newDay = day;

      // if(newDay === 0 && !isDay) {
      //   month = month - 1;
      //   let endDate = moment(`${year}-${month}`).endOf('month');
      //   newDay = moment(endDate).format('DD');
      // }

      day = !isDay ? newDay : day;
      return `${year}年${month}月${day}日${
        isDay ? `(${dayOfWeek})` : "締め切り"
      }`;
    },
    setLoadingPlayer(id, isLoading) {
      if(!id && !this.isAdminUser) {
        this.isVoteOther = true;
        return;
      }
      let indexPlayer = this.event.gamers.findIndex(
        (player) => player.gamerId === id
      );

      if (indexPlayer > -1) {
        this.event.gamers[indexPlayer].isLoading = isLoading;
      }
    },
    doCopy(message) {
      this.copied = true;
      this.$copyText(message).then(
        (e) => {
          setTimeout(() => {
            this.copied = false;
          }, 2000);
        },
        function (e) {
          console.log(e);
        }
      );
    },
    hideModalConfirm() {
      this.showModalConfirm = false;
    },
    hideModalConfirmTweet() {
      this.description = "";
      this.showModalConfirmTweet = false;
    },
    renderStringtoByte(str) {
      let newByte = new Blob([str]);
      return newByte.size;
    }
  },
};
</script>
<style scoped lang="scss">
.adswrapper {
  width: 100%;
}
ins.adsbygoogle.adsbygoogle {
   height: 400px;
}
.adsbygoogle {
  min-width: 250px;
}
</style>