<template>
  <div>
    <b-modal
      ref="player_info"
      content-class="rounded-0 content-player"
      body-class="player-info position-relative p-0"
      hide-footer
      hide-header
      @hidden="resetModal"
      v-model="isShow"
    >
      <b-card
        v-if="currentPlayer"
        class="border-0 rounded-0"
        footer-class="rounded-0"
        no-body
      >
        <span @click="resetModal" class="close-modal" role="button"
          ><esports-icon-close
        /></span>
        <b-card-img-lazy
          class="rounded-0"
          :src="currentPlayer.picture"
          v-bind="mainProps('#fff')"
          :alt="currentPlayer.name"
          top
        ></b-card-img-lazy>
        <div 
          class="player-top-info position-relative p-4"
          :class="{'team-players' : currentPlayer.teams && currentPlayer.teams.length}"
        >
          <!-- <div
            class="mr-2 d-flex"
            v-for="(team, index) in currentPlayer.teams"
            :key="team.teamId"
          >
            <span class="box-black px-2 mr-3" v-if="index === 0">{{
              currentPlayer.labelGamerName
            }}</span>
            <span
              class="box-black px-2 mr-3"
              v-else
              :style="{ visibility: 'hidden' }"
              >{{ currentPlayer.labelGamerName }}</span
            >
            <span class="box-white">{{ team.name }}</span>
          </div> -->
          <!-- <div class="mr-2 my-1" v-if="!currentPlayer.teams.length">
            <span class="box-black px-2 mr-3">無所属</span>
          </div> -->
          <b-button class="title-slash border-0 rounded-0"
            ><span :title="currentPlayer.name" :style="{'margin-left': `${currentPlayer.name && currentPlayer.name.length > 20 ? '2.8rem': '1.5rem'}`}">{{ truncateText(currentPlayer.name, 20) || "" }}</span></b-button
          >
        </div>
        <b-card-footer
          class="player-footer border-0 rounded-0 pt-3 pt-md-4 px-4"
        >
          <template v-if="currentPlayer && currentPlayer.description">
            <p class="mb-2" v-if="!eventId">bio</p>
            <p class="mb-2" v-else>{{ currentPlayer.teams && currentPlayer.teams.length ? '概要' : 'bio' }}</p>
            <p class="mb-4" :style="{'white-space': 'pre-wrap'}">
              {{ currentPlayer.description }}
            </p>
          </template>
          <template
            v-if="
              currentPlayer &&
              currentPlayer.achievement &&
              currentPlayer.achievement.length
            "
          >
            <p class="mb-2">実績</p>
            <p class="mb-4">
              <!-- {{ getArchivement(currentPlayer.achievement) }} -->
              <span
                class="d-block"
                v-for="(achive, index) in currentPlayer.achievement"
                :key="index"
                >{{ achive }}</span
              >
            </p>
          </template>
          <!-- <template
          v-if="
            currentPlayer.eventWinNames && currentPlayer.eventWinNames.length
          "
        >
          <p class="mb-2">1位</p>
          <p
            class="mb-0"
            v-for="event in currentPlayer.eventWinNames"
            :key="event"
          >
            {{ event }}
          </p>
        </template> -->

          <template
            v-if="
              currentPlayer &&
              currentPlayer.gameTitles &&
              currentPlayer.gameTitles.length
            "
          >
            <p class="mt-4 mb-2">ゲーム</p>
            <p
              v-for="(game, index) in currentPlayer.gameTitles"
              class="mb-0"
              :key="index"
            >
              {{ game }}
            </p>
          </template>

          <template
            v-if="
              currentPlayer &&
              currentPlayer.teams &&
              currentPlayer.teams.length 
            "
          >
            <p class="mt-4 mb-2">{{
              currentPlayer.labelGamerName
            }}</p>
            <p
              v-for="team in currentPlayer.teams"
              :key="team.teamId"
              class="mb-0"
            >
              {{ team.name }}
            </p>
          </template>
          <template v-else>
            <!-- <p class="mt-4 mb-2">チーム名</p> -->
            <p
              v-if="currentPlayer.gamerType === 0"
              class="mt-4 mb-2">無所属</p>
          </template>

          <template v-if="currentPlayer.eventTitles">
            <p>大会名</p>
            <p v-for="(event, index) in currentPlayer.eventTitles" :key="index">
              {{ event }}
            </p>
          </template>
          <template v-if="currentPlayer.extensionInfos">
            <div
              :key="link[0]"
              v-for="link in Object.entries(currentPlayer.extensionInfos)"
            >
              <p class="mt-4 mb-2 text-capitalize">{{ link[0] }}</p>
              <p>
                <b-link :href="link[1]" v-if="link[1].includes('http://') || link[1].includes('https://')" target="_blank">{{ link[1] }}</b-link>
                <span v-else>{{ link[1] }}</span>
              </p>
            </div>
          </template>
          <template v-if="currentPlayer.twitter">
            <p class="mt-4 mb-2 text-capitalize">Twitter</p>
            <p><b-link :href="currentPlayer.twitter" target="_blank">{{ currentPlayer.twitter }}</b-link></p>
          </template>
          <template v-if="currentPlayer.deliverySite">
            <div
              :key="link[0]"
              v-for="link in Object.entries(currentPlayer.deliverySite)"
            >
              <p class="mt-4 mb-2 text-capitalize">{{ link[0] }}</p>
              <p>
                <b-link :href="link[1]" v-if="link[1].includes('http://') || link[1].includes('https://')" target="_blank">{{ link[1] }}</b-link>
                <span v-else>{{ link[1] }}</span>
              </p>
            </div>
          </template>

          <!-- <p>概要</p>
        <p class="mb-4">{{ currentPlayer.description }}</p>
        <p>故郷</p>
        <p>{{ currentPlayer.hometown }}</p> -->

          <p class="text-center py-2 mt-4" v-if="eventId && isDeadlineVote">
            <b-button
              class="btn-slash"
              :disabled="isLoading"
              @mouseover="handleOverBtn"
              @mouseleave="handleLeaveBtn"
              :class="{ 'animate__animated animate__headShake': animated }"
              v-if="!user || (user && user.roleId !== 1)"
              @click="authenticated ? voteGamer() : showLoginModal()"
            >
              <span class="position-relative">応援する</span>
              <esports-loading-button
                v-if="isLoading"
                width="30px"
                height="30px"
                outerBorder="#333"
                innerBorder="#eee"
              />
            </b-button>
          </p>
        </b-card-footer>
      </b-card>
    </b-modal>
    <b-modal
      content-class="content-vote-twitter content-vote-ticket"
      body-class="position-relative p-0 d-flex align-items-center justify-content-center"
      hide-footer
      hide-header
      size="sm"
      centered
      @hide="hideModalConfirm"
      v-model="showModalConfirm"
    >
      <div class="vote-twitter text-center">
        <h6 class="mb-2 pb-2" :class="{'vote-other': isVoteOther}">
          <template v-if="isVoteOther">
            <div class="d-flex align-items-center"><span class="twitter-id-text pr-2">TwitterID: </span>  <b-form-input class="twitter-form" v-model="nameOther" type="text"/></div>
          </template>
          <span v-else>投票してもよろしいでしょうか。</span>
        </h6>
        <p class="d-flex align-items-center justify-content-center">
          <b-form-input
            v-model.number="numberTickets"
            type="number"
            min="0"
            :max="newCurrentTickets"
            :formatter="formatter"
            @focus="showDropdown"
          ></b-form-input>
          <b-dropdown
            id="dropdown-offset"
            no-caret
            :class="[isOpen ? 'show' : '']"
          >
            <b-dropdown-item
              v-for="(option, index) in newCurrentTickets"
              :key="index"
              @click="selectValueTicket(option)"
              >{{ option }}</b-dropdown-item
            >
          </b-dropdown>
          票投票する
        </p>
        <b-button
          class="font-weight-bold btn-cancel mx-2"
          @click="hideModalConfirm"
          >閉じる</b-button
        >
        <b-button :disabled="!numberTickets || (isVoteOther && !nameOther)" class="mx-2" @click="votePlayer"
          >OK</b-button
        >
      </div>
    </b-modal>
    <b-modal
      content-class="content-vote-twitter"
      body-class="position-relative p-0 d-flex align-items-center justify-content-center"
      hide-footer
      hide-header
      size="sm"
      centered
      ref="vote_player_modal"
    >
      <div class="vote-twitter text-center">
        <h3 class="mb-0 pb-2 font-weight-bold">{{ ticketExchangePoint }}票応援したので</h3>
        <h6 class="mb-4 pb-2">ドネト{{ addPointNumber }}pt獲得しました。</h6>
        <b-button
          class="font-weight-bold btn-cancel mx-2"
          @click="hideModal('vote_player_modal')"
          >閉じる</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { dismissCount } from "@/mixins";

export default {
  name: "PlayerDetailModal",
  props: {
    currentPlayer: {
      type: [Object],
      default: () => {},
    },
    isShowPlayerModal: {
      type: Boolean,
      default: false,
    },
    isDeadlineVote: {
      type: Boolean,
      default: false,
    },
    eventId: {
      type: [Number, String],
      default: "",
    },
    currentTickets: {
      type: [Number],
      default: 0,
    },
    isVoteOther: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isShow: false,
      isLoading: false,
      showModalConfirm: false,
      numberTickets: 1,
      gamerId: "",
      isOpen: false,
      newCurrentTickets: 0,
      addPointNumber: 0,
      ticketExchangePoint: '',
      nameOther: ''
    };
  },
  mixins: [dismissCount],
  watch: {
    isShowPlayerModal(nVal) {
      this.isShow = nVal;
    },
    isVoteOther(nVal) {
      this.showModalConfirm = nVal;
    },
    currentTickets(nVal) {
      this.newCurrentTickets = nVal;
    }
  },
  computed: {
    ...mapGetters("auth", ["authenticated", "user"]),
  },
  mounted() {
    document.addEventListener("click", (event) => {
      if (!document.getElementById("dropdown-offset")) return;
      let isClickInsideElement = document
        .getElementById("dropdown-offset")
        .contains(event.target);

      if (!isClickInsideElement && event.target.nodeName !== "INPUT") {
        this.hideDropdown();
      }
    });
  },
  methods: {
    showDropdown() {
      this.isOpen = true;
    },
    hideDropdown() {
      setTimeout(() => {
        this.isOpen = false;
      }, 150);
    },
    selectValueTicket(value) {
      this.numberTickets = parseInt(value, 10);
      this.hideDropdown();
    },
    async voteGamerEvent(eventId, gamerId) {
      this.showModalConfirm = true;
      this.gamerId = gamerId;
    },
    async votePlayer() {
      this.isLoading = true;

      let params = {
        eventId: this.eventId,
        gamerId: this.gamerId,
        numberTickets: this.numberTickets,
      };

      if(this.isVoteOther) {
        delete params.gamerId;
        params.gamerOther = this.nameOther;
      }

      const result = await this.$store
        .dispatch("vote/voteGamerEvent", params)
        .catch((err) => {
          this.isLoading = false;
          if (err?.errors) {
            this.msgErrors = Object.values(err.errors);
          } else {
            this.msgErrors = [err];
          }

          this.makeToast("danger", this.msgErrors[0]);
        });

      if (result) {
        this.addPointNumber = result.addPointNumber;
        this.ticketExchangePoint = result.ticketExchangePoint;

        this.makeToast("success", result.message);

        if(this.addPointNumber > 0) {
          this.showModal('vote_player_modal');
        }
      }

      this.isLoading = false;
      this.showModalConfirm = false;
      this.$emit("resetModal", { isHide: false, isSaved: true });
    },
    async voteGamer() {
      if (this.user && this.user.roleId === 1) return;

      if(this.authenticated && this.currentTickets === 0) {
        this.newCurrentTickets = this.user.currentTickets;
      }

      if (this.newCurrentTickets < 1) {
        this.resetModal();
        this.$emit("showModalAds");
        return;
      }

      await this.voteGamerEvent(
        this.eventId,
        this.currentPlayer.playerId || this.currentPlayer.teamId
      );
    },
    resetModal() {
      this.$emit("resetModal", { isHide: false, isSaved: false });
    },
    showMsgConfirmVote(cb) {
      this.$bvModal
        .msgBoxConfirm("投票してもよろしいでしょうか。", {
          size: "sm",
          buttonSize: "sm",
          okVariant: "primary",
          okTitle: "OK",
          cancelDisabled: false,
          cancelTitle: "キャンセル",
          footerClass: "p-2",
          dialogClass: "msgBoxModal",
          hideHeaderClose: false,
          hideHeader: true,
          centered: true,
        })
        .then((value) => {
          if (value === false) return;
          if (value === null) return;

          if (value) {
            if (cb) cb();
            return;
          }

          this.resetModal();
        });
    },
    hideModalConfirm() {
      this.numberTickets = 1;
      this.gamerId = "";
      this.showModalConfirm = false;
      this.nameOther = "";
      this.$emit('hideVoteOther', false);
    },
    showLoginModal() {
      this.$emit("showLoginModal");
    },
    formatter(value) {
      if (parseInt(value, 10) > this.newCurrentTickets) return this.newCurrentTickets;
      if (parseInt(value, 10) === 0) return 1;

      return value !== "" ? +value : "";
    },
    showModal(name) {
      this.$refs[name].show();
    },
    hideModal(name) {
      this.$refs[name].hide();
    },
  },
};
</script>