<template>
  <div class="section-ranking content-vote pb-3 pt-3 pt-md-5">
      <div class="vote-info" :class="{'pb-xl-5': gamers.length}">
        <h4 class="text-center crown mb-0"><esports-icon-crown-event /></h4>
        <h4 class="text-center txt-donate-ranking">
          <!-- <span class="donate">DONATE</span> -->
          <!-- <span><span class="donate-txt">優勝者</span> RANKING</span></h4> -->
          <span>優勝者</span></h4>
        <b-list-group class="mx-3 mx-xxl-5 px-xxl-5 mt-3 pt-md-3 mt-md-4">
          <b-list-group-item
            class="d-flex align-items-center rounded-0 border-0"
            v-for="(gamer, index) in gamers"
            :key="index"
          >
            <b-avatar class="mr-2 mr-md-3"><esports-icon-circle-plus  :color="renderColor(index)" class="mr-md-2" /> {{ index + 1 }}</b-avatar>
            <span class="mr-auto name">
                <b-avatar variant="info" v-if="gamer.picture" :src="gamer.picture" class="ml-1 ml-md-0 mr-3 mr-md-4"></b-avatar>
                <b-avatar variant="info" :text="letterName(gamer.name)" class="mr-3 b-avatar-text-winner" v-else></b-avatar>
                <span class="mr-auto" :title="gamer.name">{{ truncateText(gamer.name, 30) }}</span>
            </span>
          </b-list-group-item>
        </b-list-group>
      </div>
  </div>
</template>

<script>
export default {
    name: "SectionRanking",
    props: {
      gamers: {
        type: [Array],
        default: () => []
      }
    },
    methods: {
      renderColor(index) {
        switch(index) {
          case 0:
            return '#E6B800';
          case 1: 
            return '#6B8D99';
          case 2:
            return '#C46800';
          default:
            return '#0A0A0A';
        }
      }
    }
}
</script>